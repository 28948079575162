html {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 62.5%;
  font-family: inherit;
  vertical-align: baseline;
}

body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, button, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  background: none;
}

/* remember to define focus styles! */
:focus {
  outline: 0;
}

body {
  line-height: 1;
  color: #333;
  background: white;
  font-size: 1rem;
}

ol, ul {
  list-style: none;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: separate;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
}

blockquote, q {
  quotes: "" "";
}

button, a {
  display: inline;
  cursor: pointer;
  appearance: none;
  text-decoration: underline;
}

button[disabled] {
  cursor: not-allowed;
}

/**
 * Media Queries
 * 1 em = 16 pixels
 */
/**
 * Dark navigation colors
 */
/***** Media Query Mixin *****/
@font-face {
  font-family: "Calibri";
  src: url("../fonts/calibri_light.eot");
  src: url("../fonts/calibri_light.eot?#iefix") format("embedded-opentype"), url("../fonts/calibri_light.svg#Calibri Light") format("svg"), url("../fonts/calibri_light.woff2") format("woff2"), url("../fonts/calibri_light.woff") format("woff"), url("../fonts/calibri_light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Calibri";
  src: url("../fonts/calibri_light_italic.eot");
  src: url("../fonts/calibri_light_italic.eot?#iefix") format("embedded-opentype"), url("../fonts/calibri_light_italic.svg#Calibri Light Italic") format("svg"), url("../fonts/calibri_light_italic.woff2") format("woff2"), url("../fonts/calibri_light_italic.woff") format("woff"), url("../fonts/calibri_light_italic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Calibri";
  src: url("../fonts/calibri.eot");
  src: url("../fonts/calibri.eot?#iefix") format("embedded-opentype"), url("../fonts/calibri.svg#Calibri") format("svg"), url("../fonts/calibri.woff2") format("woff2"), url("../fonts/calibri.woff") format("woff"), url("../fonts/calibri.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Calibri";
  src: url("../fonts/calibri_bold.eot");
  src: url("../fonts/calibri_bold.eot?#iefix") format("embedded-opentype"), url("../fonts/calibri_bold.svg#Calibri Bold") format("svg"), url("../fonts/calibri_bold.woff2") format("woff2"), url("../fonts/calibri_bold.woff") format("woff"), url("../fonts/calibri_bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
[class*=col-] {
  min-height: 1px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.col-12 {
  width: 100% !important;
}

.col-11 {
  width: 91.66666667% !important;
}

.col-10 {
  width: 83.33333333% !important;
}

.col-9 {
  width: 75% !important;
}

.col-8 {
  width: 66.66666667% !important;
}

.col-7 {
  width: 58.33333333% !important;
}

.col-6 {
  width: 50% !important;
}

.col-5 {
  width: 41.66666667% !important;
}

.col-4 {
  width: 33.33333333% !important;
}

.col-3 {
  width: 25% !important;
}

.col-2 {
  width: 16.66666667% !important;
}

.col-1 {
  width: 8.33333333% !important;
}

@media (max-width: 90.625em) {
  .col-lg-12 {
    width: 100% !important;
  }
}

@media (max-width: 90.625em) {
  .col-lg-11 {
    width: 91.66666667% !important;
  }
}

@media (max-width: 90.625em) {
  .col-lg-10 {
    width: 83.33333333% !important;
  }
}

@media (max-width: 90.625em) {
  .col-lg-9 {
    width: 75% !important;
  }
}

@media (max-width: 90.625em) {
  .col-lg-8 {
    width: 66.66666667% !important;
  }
}

@media (max-width: 90.625em) {
  .col-lg-7 {
    width: 58.33333333% !important;
  }
}

@media (max-width: 90.625em) {
  .col-lg-6 {
    width: 50% !important;
  }
}

@media (max-width: 90.625em) {
  .col-lg-5 {
    width: 41.66666667% !important;
  }
}

@media (max-width: 90.625em) {
  .col-lg-4 {
    width: 33.33333333% !important;
  }
}

@media (max-width: 90.625em) {
  .col-lg-3 {
    width: 25% !important;
  }
}

@media (max-width: 90.625em) {
  .col-lg-2 {
    width: 16.66666667% !important;
  }
}

@media (max-width: 90.625em) {
  .col-lg-1 {
    width: 8.33333333% !important;
  }
}

@media (max-width: 64em) {
  .col-md-12 {
    width: 100% !important;
  }
}

@media (max-width: 64em) {
  .col-md-11 {
    width: 91.66666667% !important;
  }
}

@media (max-width: 64em) {
  .col-md-10 {
    width: 83.33333333% !important;
  }
}

@media (max-width: 64em) {
  .col-md-9 {
    width: 75% !important;
  }
}

@media (max-width: 64em) {
  .col-md-8 {
    width: 66.66666667% !important;
  }
}

@media (max-width: 64em) {
  .col-md-7 {
    width: 58.33333333% !important;
  }
}

@media (max-width: 64em) {
  .col-md-6 {
    width: 50% !important;
  }
}

@media (max-width: 64em) {
  .col-md-5 {
    width: 41.66666667% !important;
  }
}

@media (max-width: 64em) {
  .col-md-4 {
    width: 33.33333333% !important;
  }
}

@media (max-width: 64em) {
  .col-md-3 {
    width: 25% !important;
  }
}

@media (max-width: 64em) {
  .col-md-2 {
    width: 16.66666667% !important;
  }
}

@media (max-width: 64em) {
  .col-md-1 {
    width: 8.33333333% !important;
  }
}

@media (max-width: 64em) {
  .col-md-0 {
    display: none;
  }
}
@media (max-width: 36.25em) {
  .col-md-0 {
    display: block;
    width: 100%;
    height: 1px;
    margin: 2em 0;
  }
}

@media (max-width: 58.75em) {
  .col-sm-12 {
    width: 100% !important;
  }
}

@media (max-width: 58.75em) {
  .col-sm-11 {
    width: 91.66666667% !important;
  }
}

@media (max-width: 58.75em) {
  .col-sm-10 {
    width: 83.33333333% !important;
  }
}

@media (max-width: 58.75em) {
  .col-sm-9 {
    width: 75% !important;
  }
}

@media (max-width: 58.75em) {
  .col-sm-8 {
    width: 66.66666667% !important;
  }
}

@media (max-width: 58.75em) {
  .col-sm-7 {
    width: 58.33333333% !important;
  }
}

@media (max-width: 58.75em) {
  .col-sm-6 {
    width: 50% !important;
  }
}

@media (max-width: 58.75em) {
  .col-sm-5 {
    width: 41.66666667% !important;
  }
}

@media (max-width: 58.75em) {
  .col-sm-4 {
    width: 33.33333333% !important;
  }
}

@media (max-width: 58.75em) {
  .col-sm-3 {
    width: 25% !important;
  }
}

@media (max-width: 58.75em) {
  .col-sm-2 {
    width: 16.66666667% !important;
  }
}

@media (max-width: 58.75em) {
  .col-sm-1 {
    width: 8.33333333% !important;
  }
}

@media (max-width: 36.25em) {
  .col-xs-12 {
    width: 100% !important;
  }
}

@media (max-width: 36.25em) {
  .col-xs-11 {
    width: 91.66666667% !important;
  }
}

@media (max-width: 36.25em) {
  .col-xs-10 {
    width: 83.33333333% !important;
  }
}

@media (max-width: 36.25em) {
  .col-xs-9 {
    width: 75% !important;
  }
}

@media (max-width: 36.25em) {
  .col-xs-8 {
    width: 66.66666667% !important;
  }
}

@media (max-width: 36.25em) {
  .col-xs-7 {
    width: 58.33333333% !important;
  }
}

@media (max-width: 36.25em) {
  .col-xs-6 {
    width: 50% !important;
  }
}

@media (max-width: 36.25em) {
  .col-xs-5 {
    width: 41.66666667% !important;
  }
}

@media (max-width: 36.25em) {
  .col-xs-4 {
    width: 33.33333333% !important;
  }
}

@media (max-width: 36.25em) {
  .col-xs-3 {
    width: 25% !important;
  }
}

@media (max-width: 36.25em) {
  .col-xs-2 {
    width: 16.66666667% !important;
  }
}

@media (max-width: 36.25em) {
  .col-xs-1 {
    width: 8.33333333% !important;
  }
}

.offset-12 {
  margin-left: 100% !important;
}

.offset-11 {
  margin-left: 91.66666667% !important;
}

.offset-10 {
  margin-left: 83.33333333% !important;
}

.offset-9 {
  margin-left: 75% !important;
}

.offset-8 {
  margin-left: 66.66666667% !important;
}

.offset-7 {
  margin-left: 58.33333333% !important;
}

.offset-6 {
  margin-left: 50% !important;
}

.offset-5 {
  margin-left: 41.66666667% !important;
}

.offset-4 {
  margin-left: 33.33333333% !important;
}

.offset-3 {
  margin-left: 25% !important;
}

.offset-2 {
  margin-left: 16.66666667% !important;
}

.offset-1 {
  margin-left: 8.33333333% !important;
}

@media (max-width: 90.625em) {
  .offset-lg-12 {
    margin-left: 100% !important;
  }
}

@media (max-width: 90.625em) {
  .offset-lg-11 {
    margin-left: 91.66666667% !important;
  }
}

@media (max-width: 90.625em) {
  .offset-lg-10 {
    margin-left: 83.33333333% !important;
  }
}

@media (max-width: 90.625em) {
  .offset-lg-9 {
    margin-left: 75% !important;
  }
}

@media (max-width: 90.625em) {
  .offset-lg-8 {
    margin-left: 66.66666667% !important;
  }
}

@media (max-width: 90.625em) {
  .offset-lg-7 {
    margin-left: 58.33333333% !important;
  }
}

@media (max-width: 90.625em) {
  .offset-lg-6 {
    margin-left: 50% !important;
  }
}

@media (max-width: 90.625em) {
  .offset-lg-5 {
    margin-left: 41.66666667% !important;
  }
}

@media (max-width: 90.625em) {
  .offset-lg-4 {
    margin-left: 33.33333333% !important;
  }
}

@media (max-width: 90.625em) {
  .offset-lg-3 {
    margin-left: 25% !important;
  }
}

@media (max-width: 90.625em) {
  .offset-lg-2 {
    margin-left: 16.66666667% !important;
  }
}

@media (max-width: 90.625em) {
  .offset-lg-1 {
    margin-left: 8.33333333% !important;
  }
}

@media (max-width: 64em) {
  .offset-md-12 {
    margin-left: 100% !important;
  }
}

@media (max-width: 64em) {
  .offset-md-11 {
    margin-left: 91.66666667% !important;
  }
}

@media (max-width: 64em) {
  .offset-md-10 {
    margin-left: 83.33333333% !important;
  }
}

@media (max-width: 64em) {
  .offset-md-9 {
    margin-left: 75% !important;
  }
}

@media (max-width: 64em) {
  .offset-md-8 {
    margin-left: 66.66666667% !important;
  }
}

@media (max-width: 64em) {
  .offset-md-7 {
    margin-left: 58.33333333% !important;
  }
}

@media (max-width: 64em) {
  .offset-md-6 {
    margin-left: 50% !important;
  }
}

@media (max-width: 64em) {
  .offset-md-5 {
    margin-left: 41.66666667% !important;
  }
}

@media (max-width: 64em) {
  .offset-md-4 {
    margin-left: 33.33333333% !important;
  }
}

@media (max-width: 64em) {
  .offset-md-3 {
    margin-left: 25% !important;
  }
}

@media (max-width: 64em) {
  .offset-md-2 {
    margin-left: 16.66666667% !important;
  }
}

@media (max-width: 64em) {
  .offset-md-1 {
    margin-left: 8.33333333% !important;
  }
}

@media (max-width: 58.75em) {
  .offset-sm-12 {
    margin-left: 100% !important;
  }
}

@media (max-width: 58.75em) {
  .offset-sm-11 {
    margin-left: 91.66666667% !important;
  }
}

@media (max-width: 58.75em) {
  .offset-sm-10 {
    margin-left: 83.33333333% !important;
  }
}

@media (max-width: 58.75em) {
  .offset-sm-9 {
    margin-left: 75% !important;
  }
}

@media (max-width: 58.75em) {
  .offset-sm-8 {
    margin-left: 66.66666667% !important;
  }
}

@media (max-width: 58.75em) {
  .offset-sm-7 {
    margin-left: 58.33333333% !important;
  }
}

@media (max-width: 58.75em) {
  .offset-sm-6 {
    margin-left: 50% !important;
  }
}

@media (max-width: 58.75em) {
  .offset-sm-5 {
    margin-left: 41.66666667% !important;
  }
}

@media (max-width: 58.75em) {
  .offset-sm-4 {
    margin-left: 33.33333333% !important;
  }
}

@media (max-width: 58.75em) {
  .offset-sm-3 {
    margin-left: 25% !important;
  }
}

@media (max-width: 58.75em) {
  .offset-sm-2 {
    margin-left: 16.66666667% !important;
  }
}

@media (max-width: 58.75em) {
  .offset-sm-1 {
    margin-left: 8.33333333% !important;
  }
}

@media (max-width: 36.25em) {
  .offset-xs-12 {
    margin-left: 100% !important;
  }
}

@media (max-width: 36.25em) {
  .offset-xs-11 {
    margin-left: 91.66666667% !important;
  }
}

@media (max-width: 36.25em) {
  .offset-xs-10 {
    margin-left: 83.33333333% !important;
  }
}

@media (max-width: 36.25em) {
  .offset-xs-9 {
    margin-left: 75% !important;
  }
}

@media (max-width: 36.25em) {
  .offset-xs-8 {
    margin-left: 66.66666667% !important;
  }
}

@media (max-width: 36.25em) {
  .offset-xs-7 {
    margin-left: 58.33333333% !important;
  }
}

@media (max-width: 36.25em) {
  .offset-xs-6 {
    margin-left: 50% !important;
  }
}

@media (max-width: 36.25em) {
  .offset-xs-5 {
    margin-left: 41.66666667% !important;
  }
}

@media (max-width: 36.25em) {
  .offset-xs-4 {
    margin-left: 33.33333333% !important;
  }
}

@media (max-width: 36.25em) {
  .offset-xs-3 {
    margin-left: 25% !important;
  }
}

@media (max-width: 36.25em) {
  .offset-xs-2 {
    margin-left: 16.66666667% !important;
  }
}

@media (max-width: 36.25em) {
  .offset-xs-1 {
    margin-left: 8.33333333% !important;
  }
}

*, :after, :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  background: transparent;
  font-size: 10px;
  height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; /* Androids */
}

body {
  background-color: #fafafa;
  font-family: "Calibri", sans-serif !important;
}
body.overflow {
  overflow: hidden;
}

.strong {
  font-weight: bold;
}

textarea {
  max-width: 100%;
  font-size: 1.4rem;
}

td, th {
  vertical-align: middle;
}

pre {
  font-size: 1.4rem;
  line-height: 10px;
  font-family: monospace;
  margin-bottom: 10px;
}

select {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  background-color: #f0f0f0;
  max-width: 350px;
  border: 1px solid #d7d7d7;
  height: 30px;
  padding: 0 0 0 10px;
  color: #444;
  font-size: 12px;
}

hr {
  color: #f3f3f3;
  margin: 15px 0;
}

h1,
h2 {
  color: #333;
  font-weight: bold;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.6rem;
}

h3,
h4 {
  font-size: 1.4rem;
}

p {
  font-size: 1.4rem;
  line-height: 14px;
}
p > a, p button {
  color: #006a2e;
  text-decoration: none;
}
p > a:hover, p button:hover {
  color: #008037;
}

strong {
  font-weight: bold;
}

.margin-top {
  margin-bottom: 0;
  margin-top: 10px;
}

.padding-left {
  padding-left: 15px;
}

.padding-bottom {
  padding-bottom: 15px;
}

.hidden {
  display: none !important;
}

.clear {
  clear: both;
}

.blur {
  -webkit-filter: blur(3px);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Blur=3)";
  filter: blur(3px);
}

.left {
  float: left;
}

.right {
  float: right;
}

.body-wrapper {
  width: 100%;
  height: 100%;
}

a.text-link {
  color: #006a2e;
  text-decoration: none;
}
a.text-link:hover {
  color: #008037;
  text-decoration: underline;
}
a.disabled :not(.btn, .btn *) {
  color: #d3d3d3 !important;
  cursor: not-allowed;
}

.d-none {
  display: none !important;
}
.d-flex {
  display: flex !important;
}
.d-block {
  display: block !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}

.v-hidden {
  visibility: hidden;
}

.border {
  border: 1px solid #e3e3e3;
}
.border-l {
  border-left: 1px solid #e3e3e3;
}
.border-r {
  border-right: 1px solid #e3e3e3;
}
.border-t {
  border-top: 1px solid #e3e3e3;
}
.border-b {
  border-bottom: 1px solid #e3e3e3;
}

.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: 10px !important;
}
.m-2 {
  margin: 20px !important;
}
.m-3 {
  margin: 40px !important;
}

.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 10px !important;
}
.p-2 {
  padding: 20px !important;
}
.p-3 {
  padding: 40px !important;
}

.pl-0 {
  padding-left: 0 !important;
}
.pl-1 {
  padding-left: 10px !important;
}
.pl-2 {
  padding-left: 20px !important;
}
.pl-3 {
  padding-left: 40px !important;
}

.pr-0 {
  padding-right: 0 !important;
}
.pr-1 {
  padding-right: 10px !important;
}
.pr-2 {
  padding-right: 20px !important;
}
.pr-3 {
  padding-right: 40px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 10px !important;
}
.pb-2 {
  padding-bottom: 20px !important;
}
.pb-3 {
  padding-bottom: 40px !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-1 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.py-2 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.py-3 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.mt-1 {
  margin-top: 10px !important;
}
.mt-2 {
  margin-top: 20px !important;
}
.mt-3 {
  margin-top: 40px !important;
}

.mr-0 {
  margin-right: 0 !important;
}
.mr-1 {
  margin-right: 10px !important;
}
.mr-2 {
  margin-right: 20px !important;
}
.mr-3 {
  margin-right: 40px !important;
}

.ml-0 {
  margin-left: 0 !important;
}
.ml-1 {
  margin-left: 10px !important;
}
.ml-2 {
  margin-left: 20px !important;
}
.ml-3 {
  margin-left: 40px !important;
}

.mb-1 {
  margin-bottom: 10px !important;
}
.mb-2 {
  margin-bottom: 20px !important;
}
.mb-3 {
  margin-bottom: 40px !important;
}

.mx-1 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.mx-2 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
.mx-3 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.my-1 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.my-2 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.my-3 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.f-13 {
  font-size: 13px;
}

.float-right {
  float: right !important;
}
.float-left {
  float: left !important;
}

.l-30 {
  line-height: 30px;
}

.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}

.header {
  background-color: #FFF;
  border-bottom: 1px solid #e2e2e2;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 500;
}
.header.lock {
  display: none;
}
.header a.logo {
  float: left;
  padding: 0;
  margin-right: 10px;
  border-left: 0;
}
.header a.logo:hover {
  background-color: #f6f6f6;
}
.header a.logo img {
  display: block;
  height: 60px;
}
.header a.logo img.header-small-logo {
  display: none;
}
.header a {
  border-left: 1px solid #e2e2e2;
  -webkit-transition: background 0.2s ease;
  -moz-transition: background 0.2s ease;
  -ms-transition: background 0.2s ease;
  -o-transition: background 0.2s ease;
  transition: background 0.2s ease;
}
.header a.active, .header a:hover {
  background-color: #f6f6f6;
}
.header .header-links {
  float: right;
}
.header .header-links .welcome-msg {
  padding: 0 20px;
}
.header .header-links .internal-info-toggle {
  padding: 0 20px;
}
.header .header-links .internal-info-toggle label {
  line-height: 30px;
}
.header .header-links .quickrfq-btn {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  background-color: #ffba00;
  color: #fff;
  padding: 10px;
  line-height: 16px;
  height: auto;
  margin: 10px;
}
.header .header-links .quickrfq-btn span {
  line-height: 2rem;
  height: auto;
  color: #ffffff;
}
.header .header-links a, .header .header-links span {
  float: left;
  height: 59px;
  display: block;
  padding: 0 10px;
  font-size: 1.4rem;
  color: #666666;
  text-decoration: none;
  line-height: 60px;
}
.header .header-links a .header-link-text, .header .header-links span .header-link-text {
  padding: 0 5px;
}
.header .header-links a .icon, .header .header-links span .icon {
  font-size: 2rem;
  padding: 0 5px;
}
@media screen and (max-width: 58.75em) {
  .header .header-links {
    display: none;
  }
}
@media screen and (max-width: 100em) {
  .header .header-links .welcome-msg {
    display: none;
  }
}
@media screen and (max-width: 90.625em) {
  .header .header-links span.header-link-text {
    display: none;
  }
}
@media screen and (max-width: 100em) {
  .header .header-links .welcome-msg {
    display: none;
  }
}
.header .meta-menu-btn {
  display: none;
  float: right;
  height: 59px;
  width: 60px;
  font-size: 1.4rem;
  text-decoration: none;
  line-height: 60px;
  padding: 15px;
}
@media screen and (max-width: 64em) {
  .header .meta-menu-btn {
    display: block;
  }
}
.header .meta-menu-btn span {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  background-color: #666666;
  margin-top: 5px;
  height: 4px;
  display: block;
}
@media screen and (max-width: 64em) {
  .header a.logo img {
    display: none;
  }
  .header a.logo img.header-small-logo {
    display: block;
    height: 60px;
  }
}

.btn {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: background 0.2s ease;
  -moz-transition: background 0.2s ease;
  -ms-transition: background 0.2s ease;
  -o-transition: background 0.2s ease;
  transition: background 0.2s ease;
  background-color: #006a2e;
  color: #FFF;
  font-weight: bold;
  padding: 8px 15px;
  margin: 0px 2px;
  border: 0;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  font-size: 1.3rem;
}
.btn:hover {
  background-color: #008037;
}
.btn:active {
  -webkit-box-shadow: inset 0 0 2px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 0 2px 1px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
.btn.disabled, .btn [disabled] {
  background-color: #d3d3d3;
  cursor: not-allowed;
}
.btn.small {
  padding: 2px 10px;
}
.btn.yellow {
  background-color: #ffba00;
}
.btn.yellow:hover {
  background-color: #fab700;
}
.btn.yellow:hover .icon {
  background-color: #fab700;
}
.btn.red {
  background-color: #ff2600;
}
.btn.red:hover {
  background-color: #ff4726;
}
.btn.red:hover .icon {
  background-color: #ff4726;
}
.btn.no-after::after {
  display: none;
}
.btn .icon {
  vertical-align: middle;
}
.btn.clean-btn {
  background: none;
  color: #999;
}
.btn.clean-btn:hover {
  color: #666;
}
.btn.clean-btn:hover .icon {
  color: #666;
}
.btn.clean-btn.green {
  color: #006a2e;
}
.btn.clean-btn.green:hover {
  color: #008037;
}
.btn.clean-btn.green:hover .icon {
  color: #008037;
}
.btn.clean-btn:active {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.btn.secondary {
  background: none;
  color: #006a2e;
}
.btn.secondary .icon {
  color: #006a2e;
}

.table-link {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  background-color: #006a2e;
  color: #FFF;
  text-decoration: none;
  padding: 5px 10px;
  margin: 0 2px;
  display: inline-block;
}
.table-link:hover, .table-link.viewed {
  background-color: #fab700;
}
.table-link .icon {
  vertical-align: middle;
  font-size: 1.4rem;
}
.table-link.red {
  background-color: #ff2600;
}
.table-link.red:hover, .table-link.red.viewed {
  background-color: #ff4726;
}

.utils {
  display: inline-block;
  vertical-align: middle;
  margin: 4px 0;
  width: 100%;
}
.utils .util-link {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  color: #FFF;
  text-decoration: none;
  float: right;
  line-height: 24px;
  display: inline-block;
  font-size: 1.4rem;
  padding: 3px 10px;
}
.utils .util-link.red {
  background-color: #ff2600;
}
.utils .util-link.red:hover {
  background-color: #ff4726;
}
.utils .util-link.green {
  background-color: #006a2e;
}
.utils .util-link.green:hover {
  background-color: #008037;
}

.pulse-button {
  box-shadow: 0 0 0 0 rgba(0, 106, 46, 0.5);
  -webkit-animation: pulsebtn 3s 3;
}
.pulse-button:hover, .pulse-button:focus .pulse-button:focus-within, .pulse-button:active {
  -webkit-animation: none;
}

input[type=checkbox] {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  opacity: 0 !important;
  visibility: hidden;
  pointer-events: none;
}
input[type=checkbox].disabled {
  cursor: not-allowed;
}
input[type=checkbox].disabled:hover {
  background-position: 3px 0px;
}

input[type=checkbox] + label {
  padding-left: 28px !important;
  height: 30px;
  display: inline-block;
  line-height: 30px;
  background-repeat: no-repeat;
  background-position: 3px 0px;
  vertical-align: middle;
  cursor: pointer;
  margin-left: 3px;
  background-image: url("../images/checkboxes.png");
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
input[type=checkbox] + label:hover {
  background-position: 3px -28px;
}
input[type=checkbox] + label.disabled {
  cursor: not-allowed;
}
input[type=checkbox] + label.disabled:hover {
  background-position: 3px 0px;
}

input[type=checkbox]:checked + label {
  background-position: 3px -56px;
}

input[type=checkbox]:checked:disabled + label {
  opacity: 1;
  background-position: 3px -112px;
}

input[type=checkbox]:checked:disabled:hover + label {
  opacity: 1;
  background-position: 3px -112px;
}

input[type=checkbox]:disabled + label {
  opacity: 0.5;
  cursor: not-allowed;
  background-position: 3px 0px;
}

input[type=checkbox]:disabled:hover + label {
  background-position: 3px 0px;
}

input.inherit-parent[type=checkbox]:checked + label {
  background-position: 3px -112px;
}

input.disabled[type=checkbox]:checked + label {
  background-position: 3px -112px;
}

.passwordCheck .icon-check {
  color: #006a2e;
}
.passwordCheck .icon-error {
  color: #ff2600;
}

.errors {
  position: absolute;
  right: 0;
}
.errors .error {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 1.2rem;
  color: #FFF;
  background-color: #ff2600;
  display: block;
  height: 30px;
  line-height: 30px;
  margin-left: 10px;
  padding: 0 10px;
}
.errors .error::after {
  border-bottom: 5px solid transparent;
  border-right: 10px solid #ff2600;
  border-top: 5px solid transparent;
  clear: both;
  content: "";
  height: 0;
  left: 1%;
  position: absolute;
  top: 32%;
  width: 0;
}

.input-form .input-row:has(.error), .input-form .form-group:has(.error) {
  position: relative;
}
.input-form label.error {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #ff2600;
  bottom: 20px;
  color: white;
  height: 30px;
  line-height: 32px;
  padding: 0px 5px;
  position: absolute;
  right: 30px;
  text-align: center;
  width: auto;
  z-index: 1;
  font-weight: normal;
}
.input-form label.error:after {
  content: "";
  position: absolute;
  top: 85%;
  right: 5px;
  margin-left: -8px;
  width: 0;
  height: 0;
  border-top: 10px solid #ff2600;
  border-right: 0px solid transparent;
  border-left: 10px solid transparent;
}

.icon {
  text-decoration: none !important;
}

span.text-icon, a.text-icon {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  height: 30px;
  margin-left: 7px;
  width: 30px;
  font-weight: bold;
  display: inline-block;
  text-align: center;
}
span.big-icon, a.big-icon {
  color: #006a2e;
  font-size: 2rem;
  height: 24px;
  line-height: 22px;
  margin: 0 5px;
  width: 24px;
  text-decoration: none;
}
span.big-icon.yellow-icon, a.big-icon.yellow-icon {
  color: #ffba00;
}
span.big-icon.red-icon, a.big-icon.red-icon {
  color: #ff2600;
}
span.big-icon.grey-icon, a.big-icon.grey-icon {
  color: #e3e3e3;
}
span.circled-icon, a.circled-icon {
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  border-radius: 12px;
  background-color: #006a2e;
  color: #FFF;
  float: right;
  font-size: 1.4rem;
  height: 24px;
  line-height: 22px;
  margin-left: 5px;
  text-align: center;
  width: 24px;
  text-decoration: none;
}
span.circled-icon.yellow-icon, a.circled-icon.yellow-icon {
  background-color: #ffba00;
}
span.circled-icon.red-icon, a.circled-icon.red-icon {
  background-color: #ff2600;
}
span.circled-icon.grey-icon, a.circled-icon.grey-icon {
  background-color: #e3e3e3;
}

.icon-question:before {
  content: "?";
  font-family: "Calibri", sans-serif !important;
  font-size: 1.5rem;
  font-weight: bold;
}

@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?q4preb");
  src: url("../fonts/icomoon.eot?q4preb#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?q4preb") format("truetype"), url("../fonts/icomoon.woff?q4preb") format("woff"), url("../fonts/icomoon.svg?q4preb#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-checkmark:before {
  content: "\ea10";
}

.icon-feed:before {
  content: "\f09e";
}

.icon-rss:before {
  content: "\f09e";
}

.icon-wrench:before {
  content: "\f0ad";
}

.icon-chain:before {
  content: "\f0c1";
}

.icon-link:before {
  content: "\f0c1";
}

.icon-plus:before {
  content: "\f067";
}

.icon-question-circle:before {
  content: "\f059";
}

.icon-cloud-download:before {
  content: "\f0ed";
}

.icon-chain-broken:before {
  content: "\f127";
}

.icon-unlink:before {
  content: "\f127";
}

.icon-dropbox:before {
  content: "\f16b";
}

.icon-question-circle-o:before {
  content: "\f29c";
}

.icon-close:before {
  content: "\e900";
}

.icon-floppy-o:before {
  content: "\e901";
}

.icon-calendar-check-o:before {
  content: "\e902";
}

.icon-calendar-times-o:before {
  content: "\e903";
}

.icon-calendar-minus-o:before {
  content: "\e904";
}

.icon-calendar-plus-o:before {
  content: "\e905";
}

.icon-calendar-o:before {
  content: "\e906";
}

.icon-add-inverted:before {
  content: "\e907";
}

.icon-add:before {
  content: "\e908";
}

.icon-attention-inverted:before {
  content: "\e909";
}

.icon-attention:before {
  content: "\e90a";
}

.icon-browse:before {
  content: "\e90b";
}

.icon-bullhorn:before {
  content: "\e90c";
}

.icon-calendar:before {
  content: "\e90d";
}

.icon-cart:before {
  content: "\e90e";
}

.icon-check-inverted:before {
  content: "\e90f";
}

.icon-check:before {
  content: "\e910";
}

.icon-clip:before {
  content: "\e911";
}

.icon-clock:before {
  content: "\e912";
}

.icon-cloud_download:before {
  content: "\e913";
}

.icon-cloud_upload:before {
  content: "\e914";
}

.icon-contacts:before {
  content: "\e915";
}

.icon-current:before {
  content: "\e916";
}

.icon-document:before {
  content: "\e917";
}

.icon-down-inverted:before {
  content: "\e918";
}

.icon-down:before {
  content: "\e919";
}

.icon-error-inverted:before {
  content: "\e91a";
}

.icon-error:before {
  content: "\e91b";
}

.icon-funnel:before {
  content: "\e91c";
}

.icon-globus:before {
  content: "\e91d";
}

.icon-heart:before {
  content: "\e91e";
}

.icon-home:before {
  content: "\e91f";
}

.icon-in:before {
  content: "\e920";
}

.icon-info-inverted:before {
  content: "\e921";
}

.icon-info:before {
  content: "\e922";
}

.icon-inkpen:before {
  content: "\e923";
}

.icon-layers:before {
  content: "\e924";
}

.icon-left-inverted:before {
  content: "\e925";
}

.icon-left:before {
  content: "\e926";
}

.icon-lightning:before {
  content: "\e927";
}

.icon-link1:before {
  content: "\e928";
}

.icon-location:before {
  content: "\e929";
}

.icon-lock:before {
  content: "\e92a";
}

.icon-mail:before {
  content: "\e92b";
}

.icon-message:before {
  content: "\e92c";
}

.icon-new_post:before {
  content: "\e92d";
}

.icon-out:before {
  content: "\e92e";
}

.icon-paper_plane:before {
  content: "\e92f";
}

.icon-pencil:before {
  content: "\e930";
}

.icon-phone:before {
  content: "\e931";
}

.icon-pin-1:before {
  content: "\e932";
}

.icon-pin:before {
  content: "\e933";
}

.icon-portfolio:before {
  content: "\e934";
}

.icon-printer:before {
  content: "\e935";
}

.icon-profile:before {
  content: "\e936";
}

.icon-recycle_bin:before {
  content: "\e937";
}

.icon-remove-inverted:before {
  content: "\e938";
}

.icon-remove:before {
  content: "\e939";
}

.icon-right-inverted:before {
  content: "\e93a";
}

.icon-right:before {
  content: "\e93b";
}

.icon-sandglass:before {
  content: "\e93c";
}

.icon-search:before {
  content: "\e93d";
}

.icon-settings:before {
  content: "\e93e";
}

.icon-share:before {
  content: "\e93f";
}

.icon-star1:before {
  content: "\e940";
}

.icon-stats:before {
  content: "\e941";
}

.icon-storage:before {
  content: "\e942";
}

.icon-up-inverted:before {
  content: "\e943";
}

.icon-up:before {
  content: "\e944";
}

.icon-update:before {
  content: "\e945";
}

.icon-burger:before {
  content: "\e946";
}

.icon-business:before {
  content: "\e947";
}

.icon-euro:before {
  content: "\e948";
}

.icon-eq:before {
  content: "\e949";
}

.icon-label:before {
  content: "\e94a";
}

.icon-off:before {
  content: "\e94b";
}

.icon-yes:before {
  content: "\e94c";
}

.icon-stop:before {
  content: "\e94d";
}

.icon-rfq:before {
  content: "\e94e";
}

.icon-headset_mic:before {
  content: "\e951";
}

.icon-newspaper:before {
  content: "\e94f";
}

.icon-address-book:before {
  content: "\e950";
}

.icon-bubbles3:before {
  content: "\e96f";
}

.icon-cog:before {
  content: "\e994";
}

.icon-star-inverted:before {
  content: "\f005";
}

.flash-notice {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #fff;
  font-size: 1.6rem;
  display: flex;
  align-items: stretch;
  margin-bottom: 10px;
}
.flash-notice.success {
  background-color: #006a2e;
}
.flash-notice.warning {
  background-color: #ffba00;
}
.flash-notice.error {
  background-color: #ff2600;
}
.flash-notice.info {
  background-color: #00aaff;
}
.flash-notice.opentasks {
  cursor: pointer;
}
.flash-notice .flash-icon, .flash-notice .flash-close {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}
.flash-notice .flash-label {
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
  padding-top: 10px;
  padding-bottom: 10px;
  flex-grow: 1;
}
.flash-notice .flash-close {
  -webkit-transition: background-color 0.2s ease;
  -moz-transition: background-color 0.2s ease;
  -ms-transition: background-color 0.2s ease;
  -o-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  color: #FFF;
  text-decoration: none;
  border-left: 1px solid rgba(255, 255, 255, 0.25);
}
.flash-notice .flash-close:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.flash-notice .flash-close .icon {
  font-size: 1.8rem;
}

.not-important-flashes {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 400px;
  margin-bottom: -10px;
}
.not-important-flashes .col-12 {
  padding-left: 0;
  padding-right: 0;
}

.login-body {
  background: url("../../images/backbins.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.login-body .header a.logo {
  float: none;
  display: block;
}
.login-body .header a.logo img {
  display: block !important;
  margin: 0 auto;
}

.login-content-wrapper {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  top: 100px;
  left: 50%;
  margin-left: -200px;
  position: absolute;
  width: 400px;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.3);
}
@media screen and (max-width: 58.75em) {
  .login-content-wrapper {
    margin-left: -160px;
    width: 320px;
  }
}
.login-content-wrapper .login-error {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  font-size: 1.6rem;
  padding: 0 10px;
  max-width: 400px;
  margin-bottom: 10px;
  text-align: center;
  height: auto;
}
.login-content-wrapper .login-error .close-icon {
  display: none;
}
.login-content-wrapper .login-error a {
  font-weight: bold;
  text-decoration: underline;
  color: #FFF;
}
.login-content-wrapper .login-error a:hover {
  text-decoration: none;
}
.login-content-wrapper .login-form-container {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
  overflow: hidden;
}
.login-content-wrapper .login-form-container h1 {
  background-color: #006a2e;
  letter-spacing: 1px;
  margin-bottom: 20px;
  padding: 15px 20px 12px;
  color: #fff;
  font-size: 2.4rem;
  text-align: center;
  font-weight: normal;
}
.login-content-wrapper .login-form-container form {
  padding: 0 20px;
  margin-bottom: 20px;
}
.login-content-wrapper .login-form-container .input-row {
  position: relative;
}
.login-content-wrapper .login-form-container .input-row label {
  display: none;
}
.login-content-wrapper .login-form-container .input-row label.rememberme {
  display: block;
  font-size: 1.4rem;
  line-height: 3rem;
  margin-bottom: 10px;
  margin-left: 5px;
  padding-left: 30px !important;
}
.login-content-wrapper .login-form-container .input-row label.error {
  font-size: 1.4rem;
  right: 14px;
  bottom: 55px;
}
.login-content-wrapper .login-form-container .input-row input {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #e3e3e3;
  font-size: 1.6rem;
  padding: 15px 10px;
  width: 100%;
  margin-bottom: 10px;
}
.login-content-wrapper .login-form-container .input-row input:focus {
  border: 1px solid #d3d3d3;
}
.login-content-wrapper .login-form-container .input-row input#username, .login-content-wrapper .login-form-container .input-row input#password, .login-content-wrapper .login-form-container .input-row input.password {
  background-position: right center;
  background-repeat: no-repeat;
  padding-right: 36px;
}
.login-content-wrapper .login-form-container .input-row input#username {
  background-image: url("../../../public/images/icons/login-user-icon.png");
}
.login-content-wrapper .login-form-container .input-row input#password, .login-content-wrapper .login-form-container .input-row input.password {
  background-image: url("../../../public/images/icons/login-pass-icon.png");
}
.login-content-wrapper .login-form-container .input-row .btn {
  width: 100%;
  padding: 10px;
  text-align: center;
  font-size: 1.6rem;
  font-weight: normal;
  letter-spacing: 1px;
}
.login-content-wrapper .login-form-container .input-row .btn::after {
  display: none;
}
.login-content-wrapper .login-form-container.reset-form {
  margin-top: -250px;
}
.login-content-wrapper .reset-msg {
  color: #666;
  margin-bottom: 10px;
}
.login-content-wrapper .reset-msg.padding {
  margin-bottom: 20px;
  padding: 0 20px;
}
.login-content-wrapper .reset-msg p {
  padding: 0 0 10px;
}
.login-content-wrapper .voorwaarden {
  color: #999;
  font-size: 1rem;
  margin: 15px 0;
  text-align: center;
  width: 100%;
}
.login-content-wrapper .voorwaarden a {
  color: #006a2e;
  text-decoration: none;
}
.login-content-wrapper .voorwaarden a:hover {
  color: #008037;
}
.login-content-wrapper .link-btn {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  background-color: #006a2e;
  color: #fff;
  display: block;
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: 10px;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  width: 100%;
}
.login-content-wrapper .link-btn:hover {
  background-color: #008037;
}
.login-content-wrapper .msg-wrapper {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  background-color: #fafafa;
  margin-top: 10px;
  padding: 20px;
}
.login-content-wrapper .msg-wrapper .msg-nl, .login-content-wrapper .msg-wrapper .msg-fr {
  font-size: 1.2rem;
}
.login-content-wrapper .msg-wrapper .msg-nl {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e3e3e3;
}

.compatiblebrowser {
  display: none;
  text-align: center;
}

.reset-link {
  text-align: right;
}
.reset-link a {
  font-size: 14px;
  text-decoration: none;
  color: #006a2e;
  line-height: 27px;
}

.col-12 {
  float: inherit;
  position: relative;
  min-height: 1px;
  padding-left: 0;
  padding-right: 0;
}